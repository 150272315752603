/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800");
/*Boxed layout width*/
/**
 * Table Of Content
 *
 * 	1. Colors
 *	2. Typography
 *	3. Forms
 *	4. Tabs
 */
/*******************
 Gradiant mixin
*******************/
/*******************
Error Page
******************/
.error-box {
    height: 100%;
    position: fixed;
    width: 100%; }
.error-box .footer {
    width: 100%;
    left: 0px;
    right: 0px; }

.error-body {

    max-width: 600px;
    margin: 15% auto 0 auto;
    position: relative;
}
.error-body h1 {
    font-size: 210px;
    font-weight: 900;
    text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
    line-height: 210px; }

.rts-ai-icon {
    display: block;
    width: 200px;
    height: 100px;
    background: url("/static/images/icon-darkblue.svg") no-repeat center center;
    background-size: 125px;
    position: absolute;
    right: 15px;
    top: -80px;
}

@media only screen and (max-width: 450px) {
    .error-body {
        max-width: 90%;
    }
    .error-body h1 {
        font-size: 10rem;
    }

}