/*
 * # Copyright @ RTS
 */

/* Flex */
.justify-space-between {
    justify-content: space-between;
}

.align-items-baseline {
    align-items: baseline;
}

.align-self-center {
    align-self: center;
}

.align-content-stretch {
    align-content: stretch;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.gap-10 {
    gap: 10px;
}

.compact-line-height {
    line-height: 1.15; /* from normalize.css */
}


/* Buttons */
.btn-floating.btn-tiny {
    width: 24px;
    height: 24px;
}
.btn-floating.btn-tiny i {
    line-height: 24px;
}

.btn-tiny {
    height: 24px;
    line-height: 24px;
    font-size: 10px;
}
.btn-tiny i {
    font-size: 1rem;
}

.circular-progress {
    position: relative;
}

.circular-progress circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.circular-progress div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
