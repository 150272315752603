@import "materialart/style.css";
@import "materialart/pages/user-card.css";
@import "materialart/pages/authentication.css";
@import "~toastr/build/toastr.css";

@import "./colours.css";
@import "./error.css";
@import "./image.css";
@import "./result_card.css";
@import "./theme.css";

/* login */

#loginform .logo .db {
    margin-bottom: 10px;
}

/* header */

.nav-wrapper .brand-logo .main-icon {
    display: block;
    width: 100%;
    height: 100%;
    background: url("/static/images/icon.svg") no-repeat center center;
    background-size: 125px;
}

.nav-wrapper .brand-logo .small-icon {
    display: block;
    width: 100%;
    height: 100%;
    background: url("/static/images/icon-small.svg") no-repeat center center;
    background-size: 35px;
}

.mini-sidebar .small-icon {
    display: block !important;
}

.mini-sidebar .main-icon {
    display: none !important;
}

/* aside navigation */

.sidenav {
    padding-top: 15px;
}

.sidenav .collapsible-body {
    padding-left: 10px;
}

.sidenav .collapsible-body li.active > a {
    font-weight: bold;
    color: #fff;
}

@media (min-width: 768px) and (max-width: 1023px) {
    /* Override style because of bug or misconfiguration */
    [data-sidebar-position="fixed"] .left-sidebar {
        overflow: visible; } }

.image-small-box {
    background: #ffffff;
    padding: 0.25rem;
    margin-bottom: 0.25rem;

}

.image-small-box img {
    max-width: 150px;
}

.image-small-box img {
    float: left;
}


/* toast override */

#toast-container.toast-bottom-right {
    right: 12px;
    bottom: 12px;
    top: unset;
}

#toast-container .toast {
    top: 0 !important;
}

/* alerts */

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert.alert-info a {
    color: #0c5460;
}

.alert.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert.alert-warning a {
    color: #856404;
}

.alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert.alert-danger a {
    color: #721c24;
}

.alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert.alert-success a {
    color: #155724;
}

/* custom rf result table */

table.rf-result thead {
    display: block;
    float: left;
    border: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

table.rf-result tr {
    border: 0;
}

table.rf-result thead th {
    display: block;
    text-align: right;
}

table.rf-result tbody {
    position: relative;
    display: block;
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
}

table.rf-result tbody td {
    display: block;
    text-align: left;
}

table.rf-result th, table.rf-result td {
    padding: 10px;
}

/* visited label links */
a.label.label-info:visited {
    background-color: gray;
}

/* override */

@media (min-width: 601px) {
    .row.two-col-info {
        display: flex;
    }

    .row.two-col-info .col-custom-info {
        order: 2;
    }

    .row.two-col-info .card {
        min-height: 170px;
    }

    .row.add-person .card {
        min-height: 210px;
    }
}

.btn.btn-custom {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 200px;
    text-transform: none;
    line-height: 1.25rem;
    padding: .5rem;
    height: auto;
}

.btn.btn-lg.btn-custom {
    max-width: 400px;
}

.collapsible .has-arrow.dark-arrow::after {
    border-color: #5b616e;
}

.fileinput-wrapper .file-caption-name {
    border-bottom: 1px solid #9e9e9e !important;
}

.fileinput-wrapper .input-group-btn {
    margin-top: 1rem;
}

.person-image.unselected, .person.selected {
    opacity: 0.15;
}


.rotate-360-cw {
    -webkit-animation: rotate-360-cw 0.4s linear infinite both;
    animation: rotate-360-cw 0.4s linear infinite both;
}


/* ----------------------------------------------
 * Generated by Animista on 2018-5-23 8:39:17
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-90-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-360-cw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-360-cw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.dataTables_length select {
    display: inline-block;
}

.dataTables_info {
    color: #333;
    font-size: 13px;
}

.dataTables_paginate .current {
    background-color: #333;
}

table.dataTable.no-footer {
    border-bottom: 1px solid #333333;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.paginate_button:hover,
.paginate_button:hover.disabled {
    color: #fff !important;
    border: 1px solid #78909c !important;
    background: #78909c !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    color: #fff !important;
    border: 1px solid #78909c !important;
    background: #78909c !important;
}

table.dataTable td, table.dataTable th {
    border-radius: 0;
}

.table.cell-border td, .table.cell-border th {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 2px 6px;
}

.table.cell-wrap td, .table.cell-wrap th {
    white-space: normal;
}

td.cell-nowrap, th.cell-nowrap {
    white-space: nowrap;
}


/* modale */
.modal {
    top: 150px;
}

.mw-5 {
    max-width: 5rem;
}

.mw-90 {
    max-width: 90rem;
}

.modal textarea {
    min-height: 100px;
}

.rounded {
    border-radius: 1em;
}

.custom-title-vs {
    background-color: #7f9bff;
    background-image: -moz-linear-gradient(left, #7f9bff 0%, #2ddeff 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, #7f9bff), color-stop(100%, #2ddeff));
    background-image: -webkit-linear-gradient(left, #7f9bff 0%, #2ddeff 100%);
    background-image: -o-linear-gradient(left, #7f9bff 0%, #2ddeff 100%);
    background: -ms-linear-gradient(left, #7f9bff 0%, #2ddeff 100%);
    background: linear-gradient(to right, #7f9bff 0%, #2ddeff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
    padding: 1rem;
    color: #ffffff;
}

.custom-title-vs a {
    color: #ffffff;
    text-decoration: underline;
}

.custom-title-vs span {
    background: rgba(0, 0, 0, 0.1);
    padding: 0.4rem 1rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    margin-left: 1rem;
}

.table-valign-top tbody tr td {
    vertical-align: top;
}

table.plain tbody tr {
    border: 0;
}

table.compact {
    width: auto;
}

table.compact td,
table.compact th {
    padding: 0 5px;
}

html body .bg-light {
    background-color: #e9edf2 !important;

}

.vertical-text {
    line-height: 12px;
    display: block;
    /* Safari */
    -webkit-transform: rotate(-90deg) !important;

    /* Firefox */
    -moz-transform: rotate(-90deg) !important;

    /* IE */
    -ms-transform: rotate(-90deg) !important;

    /* Opera */
    -o-transform: rotate(-90deg) !important;

    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.cron-display {
    min-width: 470px;
    display: inline-block;
}

.cron-display select {
    display: inline-block;
    width: auto;
    padding: 0;
}

.cron-button {
    height: 16px;
    padding-left: 20px;
    margin-left: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

.cron-changed {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fdd;
}

.cron-controls {
    margin-left: 10px;
    color: #c77;
    font-size: 0.9em;
}

.fixed-ajax-flash {
    position: fixed;
    top: 8px;
    right: 13px;
    width: 20%;
    min-height: 100px;
    z-index: 1000;
}

/*custom for generic badge*/
span.badge.new:after {
    content: "";
}

#classList span.badge.new:after {
    content: " scènes";
}

.img-responsive {
    width: 100%;
}

button.dt-button {
    background: #78909c !important;
    color: #fff;
    border: none;
}

button.dt-button:hover {
    border: none !important;
}

.min-h-230 {
    min-height: 230px;
}

.m-w-20rem {
    min-width: 20rem;
}

.m-w-150 {
    min-width: 150px;
}

.w-10rem {
    width: 10rem !important;
}

.w-4rem {
    width: 4rem !important;
}

.m-l-45 {
    margin-left: 45px !important;
}

.m-t-3 {
    margin-top: 3px !important;
}

a.link.no-hover:hover {
    color: #9098ac;
}

/* drop zone css*/
.dz-drag-hover {
    outline: 0.3rem dashed #cacaca;
}

.drop-wrapper {
    width: 160px;
    max-height: 160px;
}

.drop-wrapper.hide {
    display: none !important;
}

.drop-area {
    margin-top: 5px;
    width: 100%;
    height: auto;
    background: #b2b9bf;
    position: relative;
    padding-bottom: 100%;
    user-select: none;
    cursor: pointer;
}

.drop-area i {
    color: #ffffff;
    position: absolute;
    left: 50%;
    margin-left: -45px;
    top: 50%;
    margin-top: -67px;
}

.drop-area span {
    position: absolute;
    width: 100%;
    color: #fff;
    font-size: 2rem;
    top: 50%;
    text-align: center;
    font-weight: bold;
    margin-top: -22.5px;
}

.drop-area .progress {
    position: absolute;
    margin: 0;
}

/* custom select face card */
.select-card {
    user-select: none;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.select-card:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .2)
}

.drop-inject .select-card.m-5 {
    margin-right: 9px !important;
}

.drop-inject .select-card.m-5:last-child {
    margin-right: 5px !important;
}

.select-card.el-add {
    outline: 1px solid #168a0c;
}

.select-card.el-add.select-card-large {
    outline-width: 2px;
}

.select-card.el-add.select-card-bg {
    background: rgba(100, 178, 75, 0.20);
}

.select-card.el-add .el-image-gradiant {
    background: rgba(100, 178, 75, 0.30);
}

.select-card.el-remove
{
    opacity: 0.25;
    outline: 2px solid red;
}

.term-card.el-remove{
    background:  red;
}

.select-card .card-body {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -11px;
    margin-bottom: 6px;
}

.input-icon-remove,
.input-icon-add {
    color: #ffffff;
    font-size: 2rem;
    padding: 2em 0 0 2em;
    margin-right: 0.5em;
    cursor: pointer;
}

.select-card .input-icon-remove:before {
    content: 'remove_circle_outline';
}

.select-card.el-remove .input-icon-remove:before {
    content: 'remove_circle';
}

.select-card .input-icon-add:before {
    content: 'add_circle_outline';
}

.select-card.el-add .input-icon-add:before {
    content: 'add_circle';
}

.text-default-color {
    color: #9098ac;
}

hr.btn-line {
    position: relative;
    top: -30px;
}

.m-h-100vh {
    min-height: 100vh;
}

.m-h-200vh {
    min-height: 200vh;
}

.ma-h-150 {
    max-height: 150px;
    overflow-y: auto;
}

.d-inline {
    display: inline-block !important;
}

.fixed-header {
    z-index: 10;
    background: #eef5f9;
    box-shadow: -5px 0 #eef5f9, 5px 0 #eef5f9, 0 15px 20px -20px white;
}

.tabs.horizontal-toc .tab a {
    color: #757575;
}

.tabs.horizontal-toc .tab a:hover {
    color: #a8a8a8;
    border-bottom: 1px solid #273146;
}

.tabs.horizontal-toc .tab a.active {
    font-weight: 500;
    color: #757575;
    border-bottom: 2px solid #273146;
}

.tabs.horizontal-toc .tab a:focus {
    background-color: transparent;
}

body {
    position: relative;
}

.card.left-inset {
    overflow: hidden;
}

.card.left-inset .card-content {
    border-bottom-left-radius: 0;
}

.card.left-inset.success .card-content {
    box-shadow: inset 2px 0 #388E3C;
}

.card.left-inset.warning .card-content {
    box-shadow: inset 2px 0 #f4511e;
}


.hidden-wrap-h-90 {
    display: flex;
    max-height: 90px;
    flex-wrap: wrap;
    overflow: hidden;
}

/* Forms */
.input-field .prefix.btn-flat {
    width: 2.5rem;
    padding: 0;
    height: 3rem;
    top: 0rem;
}

.input-field .prefix.face {
    width: 4rem;
    top: -0.6rem;
}

.input-field .prefix.large ~ input,
.input-field .prefix.large ~ textarea,
.input-field .prefix.large ~ label,
.input-field .prefix.large ~ .validate ~ label,
.input-field .prefix.large ~ .helper-text,
.input-field .prefix.large ~ .autocomplete-content,
.input-field .prefix.large ~ .select-wrapper {
    margin-left: 5rem;
    width: calc(100% - 5rem);
}

.input-field .prefix.large ~ label {
    margin-left: 5rem;
}

@media only screen and (max-width: 992px) {
    .input-field .prefix.large ~ input {
        width: calc(100% - 5rem);
    }
}

@media only screen and (max-width: 600px) {
    .input-field .prefix.large ~ input {
        width: calc(100% - 5rem);
    }
}

.btn, .btn-large, .btn-small, .btn-flat {
    text-transform: none !important;
}

.h-100vh {
    height: 100vh;
}

.black-overlay {
    background: black;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
}

/*carousel css arrow*/
.carousel-wrapper {
    position: relative;
}

.carousel-wrapper .carousel-prev,
.carousel-wrapper .carousel-next {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin-top: 10px;
}

.carousel-wrapper .carousel-next {
    right: 0;
    left: auto;
}

.carousel-wrapper .carousel-items {
    margin: 0 65px;
}

/* Frappe Grantt */
.gantt .today-highlight, .gantt .grid-header {
    display: none;
}

.gantt .grid .tick {
    display: none;
}

.gantt .bar-wrapper.completed .bar {
    fill: #0f9d58;
}

.gantt .bar-wrapper.running .bar {
    fill: #0D47A1;
}

.gantt .bar-wrapper.failed .bar {
    fill: #981D2D;
}

.gantt-container {
    margin-top: 10px;
}

.gantt-container .popup-wrapper {
    background: #273146;
}

.gantt-container .popup-wrapper .pointer {
    border-top-color: #273146;
}

.gantt-details-container {
    padding: 5px;
}

.gantt-details-container td, .gantt-details-container th {
    padding: 5px 5px;
}

.p-l-3 {
    padding-left: 3px !important;
}

.p-r-3 {
    padding-right: 3px !important;
}

.p-r-11-perc {
    padding-right: 11% !important;
}

.small-image {
    height: 88px;
    width: 88px;
}

.image-caption-overlay {
    position: relative;
}

.image-block img {
    display: block;
}

.image-caption-overlay .image-caption {
    position: absolute;
    color: #fff;
    background-color: #78909c !important;
    font-size: 0.75rem;
    padding: 1px 7px 0 7px;
    z-index: 2;
}

.image-caption-overlay.bottom-right .image-caption {
    bottom: 0px;
    right: 2px;
    border-top-left-radius: 3px;
}

/* Copy detection */
.text-nowrap {
    white-space: nowrap;
}

/* END Copy detection */

/* Result cards */
.results-container {
    display: flex;
    flex-wrap: wrap;

    margin-left: -0.34rem;
    margin-right: -0.34rem;
}

.results-container .card.result {
    max-width: 250px;
    margin-left: 0.34rem;
    margin-right: 0.34rem;
}

/* custom cards with checkbox */
.el-card-checkbox {

    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -11px;
    margin-bottom: 6px;
}

.el-card-checkbox [type="checkbox"] + span:not(.lever):before, [type="checkbox"]:not(.filled-in) + span:not(.lever):after {
    border-color: #ffffff;
}

.el-card-checkbox [type="checkbox"]:checked + span:not(.lever):before {
    /*border-color: #5a5a5a;*/
    border-top-color: transparent;
    border-left-color: transparent;

}

.el-checked {
    outline: 2px solid #5a5a5a;
    outline-offset: 2px;
}

.el-image-gradiant {
    background-image: linear-gradient(to right, transparent, rgba(23, 23, 23, 0.05));
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.term-card {
    cursor: pointer;
}
/* END Result cards */


