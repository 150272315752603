/*
 * # Copyright @ RTS
 */



.card.result.default-image .el-card-avatar {
    width: 250px;
    height: 140px;
    background-color: rgb(238, 245, 249);
}

.card.result.default-image img:before {
    content: "library_music";
    font-family: 'Material Icons';
    font-size: 5rem;
    line-height: 140px;
    margin-left: 87px;
    color: #5b616e;
}

.card.result.video.default-image img:before {
    content: "photo_library";
}

.card.result.audio.default-image img:before {
    content: "library_music";
}