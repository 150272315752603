
.big-image-box {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    /* Full height */
    height: 100vh;
    position: absolute;
    z-index: 0;
    width: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-full-height {
    height: calc(100vh - 369px);
    margin: 0 auto;
    z-index: 1;

}

.detail-image-bottom {
    z-index: 2;
    width: calc(100% - 70px);
    height: 305px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    background: #ffffff;
}

.no-result.detail-image-bottom {
    height: 115px;
}

.no-result .image-full-height {
    height: calc(100vh - 178px);
}

.carousel-face {
    height: 135px;
    overflow: hidden;
    margin-top: 5px;
}

.carousel-face-item {
    height: 135px;
    width: 500px;
}

.carousel-face-item .card-image img {
    border-radius: 50%;
}

.carousel-thumbnail {
    height: 120px;
    overflow: hidden;
}

.carousel-thumbnail-item {
    height: 100px;
    width: 100px;
}

.image-small-box {
    position: relative;
}

.image-caption {
    display: none;
    position: absolute;
    color: black;
    bottom: 0;
    font-size: 0.75rem;
    background: #ffffff;
    margin-left: 5px;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 0 12px #000000;
    z-index: 2;
}

a:hover .image-caption {
    display: block;
}