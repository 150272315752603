/*
 * # Copyright @ RTS
 */

/* Override theme */

.pagination li.active {
    background-color: #78909c; }

.collection .collection-item.active {
    background-color: #78909c;}

.collection a.collection-item {
    color: #78909c; }

.secondary-content {
    color: #78909c; }

.progress {
    background-color: #eef1f2;}

.progress .determinate {
    background-color: #78909c; }

.progress .indeterminate {
    background-color: #78909c; }

span.badge.new {
    background-color: #78909c; }

.btn:focus, .btn-large:focus, .btn-small:focus,
.btn-floating:focus {
    background-color: #5f7682; }

.btn, .btn-large, .btn-small {
    background-color: #78909c; }

.btn:hover, .btn-large:hover, .btn-small:hover {
    background-color: #879ca7; }

.btn-floating {
    background-color: #78909c; }

.btn-floating:hover {
    background-color: #78909c; }

.fixed-action-btn .fab-backdrop {
    background-color: #78909c; }

.dropdown-content li > a, .dropdown-content li > span {
    color: #78909c; }

select:focus {
    outline: 1px solid white; }

button:focus {
    background-color: #849aa5; }

input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #78909c;
    box-shadow: 0 1px 0 0 #78909c; }

input:not([type]):focus:not([readonly]) + label,
input[type=text]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=url]:not(.browser-default):focus:not([readonly]) + label,
input[type=time]:not(.browser-default):focus:not([readonly]) + label,
input[type=date]:not(.browser-default):focus:not([readonly]) + label,
input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
input[type=number]:not(.browser-default):focus:not([readonly]) + label,
input[type=search]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
    color: #78909c; }

.input-field .prefix.active {
    color: #78909c; }

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
    border: 2px solid #78909c; }

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
    background-color: #78909c; }

[type="checkbox"]:checked + span:not(.lever):before {
    border-right: 2px solid #78909c;
    border-bottom: 2px solid #78909c; }

[type="checkbox"]:indeterminate + span:not(.lever):before {
    border-right: 2px solid #78909c; }

[type="checkbox"].filled-in:checked + span:not(.lever):after {
    border: 2px solid #78909c;
    background-color: #78909c; }

[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {
    background-color: #78909c;
    border-color: #78909c; }

.switch label input[type=checkbox]:checked + .lever {
    background-color: #cacaca; }

.switch label input[type=checkbox]:checked + .lever:after {
    background-color: #78909c; }

.switch label .lever:before {
    background-color: rgba(120, 144, 156, 0.15); }

.select-wrapper input.select-dropdown:focus {
    border-bottom: 1px solid #78909c; }

input[type=range] + .thumb {
    background-color: #78909c; }

input[type=range] + .thumb .value {
    color: #78909c; }

input[type=range]::-webkit-slider-thumb {
    background: #78909c;
    background-color: #78909c; }

.keyboard-focused input[type=range]:focus:not(.active)::-webkit-slider-thumb {
    box-shadow: 0 0 0 10px rgba(120, 144, 156, 0.26); }

input[type=range]::-moz-range-thumb {
    background: #78909c; }

.keyboard-focused input[type=range]:focus:not(.active)::-moz-range-thumb {
    box-shadow: 0 0 0 10px rgba(120, 144, 156, 0.26); }

input[type=range]::-ms-thumb {
    background: #78909c; }

.keyboard-focused input[type=range]:focus:not(.active)::-ms-thumb {
    box-shadow: 0 0 0 10px rgba(120, 144, 156, 0.26); }

.sidenav li > a.btn:hover, .sidenav li > a.btn-large:hover, .sidenav li > a.btn-small:hover, .sidenav li > a.btn-large:hover {
    background-color: #879ca7; }

.sidenav li > a.btn-floating:hover {
    background-color: #78909c; }

.spinner-layer {
    border-color: #78909c; }

.datepicker-date-display {
    background-color: #78909c; }

.datepicker-table td.is-today {
    color: #78909c; }

.datepicker-table td.is-selected {
    background-color: #78909c; }

.datepicker-day-button:focus {
    background-color: rgba(126, 142, 150, 0.25); }

.datepicker-done {
    color: #78909c; }

.timepicker-digital-display {
    background-color: #78909c; }

.timepicker-tick.active,
.timepicker-tick:hover {
    background-color: rgba(120, 144, 156, 0.25); }

.timepicker-canvas line {
    stroke: #78909c; }

.timepicker-canvas-bearing {
    fill: #78909c; }

.timepicker-canvas-bg {
    fill: #78909c; }

.timepicker-close {
    color: #78909c; }

.left-sidebar .sidenav ul > li.active > .collapsible-header {
    background: grey; }